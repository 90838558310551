// src/components/Brigade/BrigadeForm.js
import React, { useState, useEffect } from 'react';
import '../../css/brigade-form.css'

const BrigadeForm = ({ onSubmit, brigade }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (brigade) {
            setName(brigade.name);
            setDescription(brigade.description);
        }
    }, [brigade]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, description });
        setName('');
        setDescription('');
    };

    return (
        <form onSubmit={handleSubmit} className="brigade-form">
        <input
            type="text"
            placeholder="Nombre de la Brigada"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="brigade-input"
        />
        <textarea
            placeholder="Descripción"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="brigade-textarea"
        />
        <button type="submit" className="brigade-button">Guardar Brigada</button>
    </form>
    );
};

export default BrigadeForm;
