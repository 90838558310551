import React, { useState, useEffect } from 'react';
import '../../css/roleForm.css';

const RoleForm = ({ onSubmit, role }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (role) {
            setName(role.name);
            setDescription(role.description);
        }
    }, [role]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, description });
        setName('');
        setDescription('');
    };

    return (
        <form onSubmit={handleSubmit} className="role-form-container">
            <input
                type="text"
                placeholder="Nombre del Rol"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
            />
            <textarea
                placeholder="Descripción"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
            />
            <button type="submit">Guardar Rol</button>
        </form>
    );
};

export default RoleForm;
