import React from 'react';
import '../../css/sectorList.css';
import exampleImage from '../../img/logoadnblanco.png'

const Imagen = () => {
  return (
    <div className="imagencontene">
      <img src={exampleImage} alt="Descripción de la imagen" className="imagen" />
    </div>
  );
}

export default Imagen;
