import React, { useEffect, useState } from 'react';
import axios from 'axios';
import UserForm from './UserForm';
import '../../css/userList.css';

const UserList = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const URL= process.env.REACT_APP_URL_LOCAL_HOST;

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        const response = await axios.get(`${URL}/api/usuarios`);
        setUsers(response.data);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${URL}/api/usuarios/${id}`);
        fetchUsers();
    };

    const handleEdit = (user) => {
        setSelectedUser(user);
    };

    const handleSubmit = async (user) => {
        if (selectedUser) {
            await axios.put(`${URL}/api/usuarios/${selectedUser._id}`, user);
        } else {
            await axios.post(`${URL}/api/usuarios`, user);
        }
        setSelectedUser(null);
        fetchUsers();
    };

    return (
        <div className="user-list-container">
            <h2 className="user-title">Usuarios</h2>
            <UserForm onSubmit={handleSubmit} user={selectedUser} />
            <ul className="user-list">
                {users.map((user) => (
                    <li key={user._id}>
                        <span className="user-info">{user.username} ({user.email})</span>
                        <div className="user-action-buttons">
                            <button onClick={() => handleEdit(user)} className="edit-button">Editar</button>
                            <button onClick={() => handleDelete(user._id)} className="delete-button">Eliminar</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UserList;
