// src/components/Circunscripcion/CircunscripcionList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircunscripcionForm from './CircunscripcionForm';
import '../../css/circunscripcionList.css';
import Cookies from 'js-cookie'; // Importa js-cookie

const CircunscripcionList = () => {
    const [circunscripciones, setCircunscripciones] = useState([]);
    const [selectedCircunscripcion, setSelectedCircunscripcion] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);
    const token = Cookies.get('token');
    const URL= process.env.REACT_APP_URL_LOCAL_HOST;
    useEffect(() => {
        fetchCircunscripciones();
    }, []);

    const fetchCircunscripciones = async () => {
        const response = await axios.get(`${URL}/api/circunscripciones`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        setCircunscripciones(response.data);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${URL}/api/circunscripciones/${id}`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        fetchCircunscripciones();
    };

    const handleEdit = (circunscripcion) => {
        setSelectedCircunscripcion(circunscripcion);
        setModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedCircunscripcion(null);
        setModalOpen(true);
    };

    const handleSubmit = async (circunscripcion) => {
        if (selectedCircunscripcion) {
            await axios.put(`${URL}/api/circunscripciones/${selectedCircunscripcion._id}`, circunscripcion, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        } else {
            await axios.post(`${URL}/api/circunscripciones`, circunscripcion, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        }
        setModalOpen(false);
        setSelectedCircunscripcion(null);
        fetchCircunscripciones();
    };

    return (
        <div>
            <h2>Circunscripciones</h2>
            <button onClick={handleAdd}>Agregar Circunscripción</button>
            {isModalOpen && (
                <CircunscripcionForm
                    onSubmit={handleSubmit}
                    circunscripcion={selectedCircunscripcion}
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                />
            )}
            <ul className="lista-circunscripcion">
                {circunscripciones.sort((a, b) => a.name.localeCompare(b.name)).map((circunscripcion) => (
                    <li key={circunscripcion._id}>
                        {circunscripcion.name} - {circunscripcion.description}
                        <button className="btn-editar" onClick={() => handleEdit(circunscripcion)}>Editar</button>
                        <button onClick={() => handleDelete(circunscripcion._id)}>Eliminar</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CircunscripcionList;
