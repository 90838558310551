// src/components/Notification.js
import React, { useEffect } from 'react';
import '../css/notification.css';

const Notification = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 5000); 
        return () => clearTimeout(timer);
    }, [onClose]);

    return (
        <div className="notification">
            <p>{message}</p>
        </div>
    );
};

export default Notification;
