import React, { useEffect, useRef, useState } from "react";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Feature from "ol/Feature";
import LineString from "ol/geom/LineString";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Point from "ol/geom/Point";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Overlay from "ol/Overlay";
import axios from 'axios';
import { getDistance } from 'ol/sphere';
import Cookies from 'js-cookie'; // Importa js-cookie


const LATITUD_CENTRO = 18.4861;
const LONGITUD_CENTRO = -69.9312;
const ZOOM = 15;

const Mapa = ({ usuario }) => {
    const mapaRef = useRef();
    const mapaInstancia = useRef();
    const [ultimaCapa, setUltimaCapa] = useState(null);
    const [sectors, setSectors] = useState([]);
    const [posicionActual, setPosicionActual] = useState(null);
    const [busqueda, setBusqueda] = useState("");
    const [sugerencias, setSugerencias] = useState([]);
    const token = Cookies.get('token');
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;
    const [infoOverlay, setInfoOverlay] = useState(null);

    useEffect(() => {
        if (!mapaInstancia.current) return;

        // Crear y asignar el elemento HTML para el overlay
        const overlayDiv = document.createElement("div");
        overlayDiv.className = "sector-info-overlay";
        overlayDiv.style.position = "absolute";
        overlayDiv.style.background = "white";
        overlayDiv.style.padding = "5px";
        overlayDiv.style.border = "1px solid black";
        
        const overlay = new Overlay({
            element: overlayDiv,
            positioning: "bottom-center",
            offset: [0, -10],
        });

        // Agregar el overlay al mapa
        mapaInstancia.current.addOverlay(overlay);
        setInfoOverlay(overlay);

        return () => {
            if (mapaInstancia.current && overlay) {
                mapaInstancia.current.removeOverlay(overlay);
            }
        };
    }, []);

    const handleMouseMove = (event) => {
        const map = mapaInstancia.current;
        const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
    
        if (feature) {
            const featureCoordinates = feature.getGeometry().getCoordinates();
            const featureLonLat = toLonLat(featureCoordinates); // Convertimos a lon/lat
            
            // Comparar la distancia entre las coordenadas del mouse y las del sector
            const sector = sectors.find((s) => {
                return s.coordinates.some((coord) => {
                    // Calculamos la distancia entre el sector y la coordenada del mouse
                    const sectorLonLat = coord; // Las coordenadas de tu sector ya deben estar en lon/lat
                    const distance = getDistance(sectorLonLat, featureLonLat);
                    return distance < 50; // Umbral de 50 metros, puedes ajustarlo según necesites
                });
            });
    
            if (sector) {
                mostrarInfoSector(sector, featureLonLat); // Mostrar info si está cerca de un sector
            }
            mapaInstancia.current.on('pointermove', handleMouseMove);
        }
    };
    

    const mostrarInfoSector = (sector, coordenadas) => {
        if (!infoOverlay) return;
    
        const overlayElement = infoOverlay.getElement();
        if (!overlayElement) return; // Verificar que el overlayElement exista
    
        console.log("Sector encontrado:", sector); // Verifica si sector es válido
    
        overlayElement.innerHTML = `
            <div style="background: white; padding: 5px; border: 1px solid black;">
                <p><strong>${sector.name}</strong></p>
                <p>Estado: ${sector.status}</p>
            </div>
        `;
    
        infoOverlay.setPosition(fromLonLat(coordenadas));
    };
    
    const handleClickSector = (event) => {
        const map = mapaInstancia.current;
        const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => feature);
    
        if (feature) {
            const featureCoordinates = feature.getGeometry().getCoordinates();
            const sector = sectors.find((s) =>
                s.coordinates.some((coord) =>
                    JSON.stringify(coord) === JSON.stringify(toLonLat(featureCoordinates))
                )
            );
            
            if (sector) {
                // Pasamos las coordenadas de la característica del mapa
                mostrarInfoSector(sector, toLonLat(featureCoordinates));
            } else {
                console.log("Sector no encontrado para las coordenadas:", toLonLat(featureCoordinates));
            }
        }
    };
    useEffect(() => {
        if (!mapaInstancia.current) return;
        mapaInstancia.current.on("click", handleClickSector);

        return () => {
            mapaInstancia.current.un("click", handleClickSector);
        };
    }, [sectors, infoOverlay]);

    const fetchSectors = async () => {
        try {
            const response = await axios.get(`${URL}/api/sectores`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
            const sortedSectors = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setSectors(sortedSectors);
        } catch (error) {
            console.error("Error al obtener los sectores:", error);
        }
    };

    const obtenerUbicacion = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    setPosicionActual([longitude, latitude]);
                    mapaInstancia.current.getView().setCenter(fromLonLat([longitude, latitude]));
                },
                (error) => {
                    console.error("Error al obtener la ubicación:", error);
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 10000,
                    timeout: 5000,
                }
            );
        } else {
            console.log("La geolocalización no está soportada en este navegador.");
        }
    };

    const dibujarSectoresEnMapa = () => {
        if (!mapaInstancia.current) return; // Asegúrate de que el mapa esté inicializado
    
        if (ultimaCapa) {
            mapaInstancia.current.removeLayer(ultimaCapa);
        }
    
        const features = sectors
            .filter(sector => sector.coordinates.length > 0)
            .map((sector) => {
                const coordenadas = sector.coordinates.map(coord => fromLonLat([coord[0], coord[1]])); 
    
                if (coordenadas.length > 1) {
                    // Dibujar una polilínea para los sectores con múltiples coordenadas
                    const polilinea = new Feature({
                        geometry: new LineString(coordenadas),
                    });
                    polilinea.setStyle(new Style({
                        stroke: new Stroke({
                            color: sector.status === 'Pendiente' ? '#ef5ba1' :
                                   sector.status === 'Proceso' ? '#ffea00' : '#00a651',  // Colores según el estado
                            width: 2,
                        }),
                    }));
                    return polilinea;
                } else {
                    // Dibujar un marcador para los sectores con solo una coordenada
                    const marcador = new Feature({
                        geometry: new Point(coordenadas[0]),
                    });
    
                    // Cambiar el color del marcador según el estado del sector
                    const marcadorColor = sector.status === 'Pendiente' ? 'Red' :
                                          sector.status === 'Proceso' ? '#ffea00' : '#00a651';
    
                    marcador.setStyle(new Style({
                        image: new CircleStyle({
                            radius: 6,
                            fill: new Fill({ color: marcadorColor }),  // Color del marcador según el estado
                            stroke: new Stroke({
                                color: 'white',
                                width: 2,
                            }),
                        }),
                    }));
    
                    return marcador;
                }
            });
    
        const nuevaCapa = new VectorLayer({
            source: new VectorSource({ features: features }),
        });
    
        mapaInstancia.current.addLayer(nuevaCapa);
        setUltimaCapa(nuevaCapa);
    };
    
    const handleBusqueda = (e) => {
        const valor = e.target.value;
        setBusqueda(valor);
        
        if (valor.length > 0) {
            const coincidencias = sectors.filter(sector =>
                sector.name.toLowerCase().includes(valor.toLowerCase())
            );
            setSugerencias(coincidencias);
        } else {
            setSugerencias([]);
        }
    };

    const seleccionarSector = (sector) => {
        setBusqueda(sector.name);
        setSugerencias([]);
        
        if (sector.coordinates.length) {
            const coordenadas = fromLonLat(sector.coordinates[0]);
            mapaInstancia.current.getView().setCenter(coordenadas);
            mapaInstancia.current.getView().setZoom(17);
            mostrarInfoSector(sector, coordenadas); // Muestra la información al buscar
        }
    };

    useEffect(() => {
        fetchSectors(); 
        mapaInstancia.current = new Map({
            target: mapaRef.current,
            layers: [
                new TileLayer({ source: new OSM() }),
            ],
            view: new View({
                center: fromLonLat([LONGITUD_CENTRO, LATITUD_CENTRO]),
                zoom: ZOOM,
            }),
        });

        obtenerUbicacion();

        return () => mapaInstancia.current.setTarget(null);
    }, []);

    useEffect(() => {
        if (sectors.length) {
            dibujarSectoresEnMapa();
        }
    }, [sectors, posicionActual]);

    return (
        <div>
            <h1>Mapa de Sectores</h1>
            <input
                type="text"
                value={busqueda}
                onChange={handleBusqueda}
                placeholder="Buscar sector..."
                style={{ width: "100%", padding: "8px", marginBottom: "8px" }}
            />
            {sugerencias.length > 0 && (
                <ul style={{ listStyleType: "none", padding: 0, margin: 0, maxHeight: "150px", overflowY: "auto", border: "1px solid #ddd" }}>
                    {sugerencias.map((sector) => (
                        <li
                            key={sector._id}
                            onClick={() => seleccionarSector(sector)}
                            style={{ padding: "8px", cursor: "pointer", borderBottom: "1px solid #ddd" }}
                        >
                            {sector.name}
                        </li>
                    ))}
                </ul>
            )}
            <div ref={mapaRef} style={{ width: "100%", height: "500px" }}></div>
        </div>
    );
};

export default Mapa;
