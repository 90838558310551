import React, { useState, useEffect } from 'react';
import axios from 'axios';
import HistoryDashboard from '../dashboard/HistoryDashboard';
import '../../css/SupervisorDashboard.css';
import Cookies from 'js-cookie'; // Importa js-cookie
const SupervisorDashboard = () => {
    const [sectors, setSectors] = useState([]);
    const [assignedBrigade, setAssignedBrigade] = useState(null);
    const [brigadeMembers, setBrigadeMembers] = useState([]);
    const [selectedSectorId, setSelectedSectorId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newStatus, setNewStatus] = useState('');
    const [comment, setComment] = useState('');
    const token = Cookies.get('token');
    const userRole = Cookies.get('role'); 
    const userId =Cookies.get('userId');
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;
    const [newScheduledDate, setNewScheduledDate] = useState('');

    // Cargar los sectores asignados al supervisor
    useEffect(() => {
        const fetchSectors = async () => {
            try {
                const response = await axios.get(`${URL}/api/sectores`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    }
                });
                const allSectors = response.data;

                // Filtrar sectores según el rol
                if (userRole === 'Administrador') {
                    setSectors(allSectors);
                } else {
                    const sectorsForSupervisor = [];
                    for (let sector of allSectors) {
                        const brigadeResponse = await axios.get(`${URL}/api/brigadas/${sector.assignedBrigade}`, {
                            headers: {
                                Authorization: token ? `Bearer ${token}` : '',
                            }
                        });

                        const brigade = brigadeResponse.data;
                        const isSupervisorAssigned = brigade.members.some(member => member._id === userId);
                        if (isSupervisorAssigned) {
                            sectorsForSupervisor.push(sector);
                        }
                    }
                    setSectors(sectorsForSupervisor);  // Asignar los sectores filtrados
                }
            } catch (error) {
                console.error('Error al obtener sectores:', error);
            }
        };

        fetchSectors();
    }, [token, userRole, userId]);

    // Cargar los miembros de la brigada asignada al sector
    const fetchBrigadeMembers = async (brigadeId) => {
        try {
            const response = await axios.get(`${URL}/api/brigadas/${brigadeId}`, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
            setAssignedBrigade(response.data);
            setBrigadeMembers(response.data.members);  // Miembros de la brigada
        } catch (error) {
            console.error('Error al obtener miembros de la brigada:', error);
        }
    };

    // Seleccionar un sector y cargar sus detalles
    const handleSelectSector = (sectorId) => {
        setSelectedSectorId(sectorId);
        const selectedSector = sectors.find(sector => sector._id === sectorId);

        if (selectedSector?.assignedBrigade) {
            fetchBrigadeMembers(selectedSector.assignedBrigade);
        } else {
            setAssignedBrigade(null);
            setBrigadeMembers([]);
        }
    };

    // Abrir el modal de edición de estado
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Cerrar el modal de edición de estado
    const closeModal = () => {
        setIsModalOpen(false);
        setNewStatus('');
        setComment('');
    };

    const handleUpdateSector = async () => {
        try {
            const response = await axios.put(`${URL}/api/sectores/${selectedSectorId}`, {
                status: newStatus,
                comment: comment,
                scheduledDate: newScheduledDate || null,  // Si no se actualiza la fecha, se mantiene como está
            }, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
    
            console.log('Sector actualizado:', response.data);
            // Cerrar el modal y actualizar la vista
            closeModal();
            setSectors((prevSectors) => 
                prevSectors.map((sector) =>
                    sector._id === selectedSectorId
                        ? { ...sector, status: newStatus, comment: comment, scheduledDate: newScheduledDate || sector.scheduledDate }
                        : sector
                )
            );
        } catch (error) {
            console.error('Error al actualizar el sector:', error);
        }
    };
    
    return (
        <div className="dashboard-container">
            <h2 className="dashboard-title">Panel del Supervisor</h2>

            <select
                className="sector-select"
                onChange={(e) => handleSelectSector(e.target.value)}
            >
                <option value="">Selecciona un sector</option>
                {sectors.map(sector => (
                    <option key={sector._id} value={sector._id}>
                        {sector.name}
                    </option>
                ))}
            </select>

            {selectedSectorId && (
                <div className="contenedorHistoria" >
                    <HistoryDashboard sectorId={selectedSectorId} />
                    {assignedBrigade && (
                        <div className="brigade-info">
                            <h3>Brigada Asignada: {assignedBrigade.name}</h3>
                            <ul>
                                {brigadeMembers.map((member) => (
                                    <li key={member._id}>{member.username}</li>
                                ))}
                            </ul>
                        </div>
                    )}

                    {/* Botón para abrir el modal */}
                    <button className="edit-status-btn" onClick={openModal}>
                        Editar Estado del Sector
                    </button>

                    {/* Modal para editar el estado y comentario */}
                    {isModalOpen && (
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <h3>Editar Estado del Sector</h3>
                                <label>Estado:</label>
                                <select
                                    value={newStatus}
                                    onChange={(e) => setNewStatus(e.target.value)}
                                >
                                    <option value="">Selecciona un estado</option>
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="Proceso">En Proceso</option>
                                    <option value="Listo">Listo</option>
                                </select>

                                <label>Comentario:</label>
                                <textarea
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />

                                {/* Campo para seleccionar la nueva fecha */}
                                <label>Fecha Programada:</label>
                                <input
                                    type="date"
                                    value={newScheduledDate}
                                    onChange={(e) => setNewScheduledDate(e.target.value)}
                                />

                                <button onClick={handleUpdateSector}>Actualizar</button>
                                <button onClick={closeModal}>Cerrar</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SupervisorDashboard;
