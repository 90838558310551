import React, { useEffect, useState } from 'react';
import BrigadeForm from './BrigadeForm';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import '../../css/brigade-list.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Cookies from 'js-cookie'; 

const BrigadeList = () => {
    const URL= process.env.REACT_APP_URL_LOCAL_HOST;
    const [brigades, setBrigades] = useState([]);
    const [selectedBrigade, setSelectedBrigade] = useState(null);
    const token = Cookies.get('token'); 

    useEffect(() => {
        fetchBrigades();
    }, []);

    const fetchBrigades = async () => {
        const response = await axios.get(`${URL}/api/brigadas`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        });
        setBrigades(response.data);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${URL}/api/brigadas/${id}`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            },
        });
        fetchBrigades();
    };

    const handleEdit = (brigade) => {
        setSelectedBrigade(brigade);
    };

    const handleSubmit = async (brigade) => {
        if (selectedBrigade) {
            await axios.put(`${URL}/api/brigadas/${selectedBrigade._id}`, brigade, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                },
            });
        } else {
            await axios.post(`${URL}/api/brigadas`, brigade);
        }
        setSelectedBrigade(null);
        fetchBrigades();
    };

    // Función para generar PDF individual
    const generatePDFForBrigade = (brigade) => {
        const doc = new jsPDF();
        doc.setFontSize(20);
        doc.text('Reporte de Brigada', 14, 16);
        doc.setFontSize(16);
        doc.text(`Brigada: ${brigade.name}`, 14, 30);
        
        let yPosition = 40;

        doc.setFontSize(14);
        if (brigade.members && brigade.members.length > 0) {
            brigade.members.forEach((member) => {
                doc.text(` - Miembro: ${member.username || member.name}`, 20, yPosition);
                yPosition += 10; // Mueve hacia abajo para el siguiente miembro
            });
        } else {
            doc.text(' - No hay miembros en esta brigada', 20, yPosition);
            yPosition += 10; // Mueve hacia abajo para el siguiente brigada
        }

        doc.save(`${brigade.name}_report.pdf`);
    };

    // Función para generar Excel
    const generateExcel = () => {
        const ws = XLSX.utils.json_to_sheet(brigades.map(brigade => ({
            Name: brigade.name,
            Members: brigade.members ? brigade.members.map(member => member.username).join(', ') : 'N/A',
        })));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Brigadas');
        XLSX.writeFile(wb, 'brigades.xlsx');
    };

    // Función para generar CSV
    const generateCSV = () => {
        const csvData = brigades.map(brigade => ({
            Name: brigade.name,
            Members: brigade.members ? brigade.members.map(member => member.username).join(', ') : 'N/A',
        }));

        const csvRows = [
            ['Name', 'Members'], // Cabeceras
            ...csvData.map(brigade => [brigade.Name, brigade.Members]),
        ];

        const csvString = csvRows.map(row => row.join(',')).join('\n');
        const blob = new Blob([csvString], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', 'brigades.csv');
        a.click();
    };

    return (
        <div className="brigade-list-container">
            <h2 className="brigade-list-title">Brigadas</h2>
            <BrigadeForm onSubmit={handleSubmit} brigade={selectedBrigade} />
            <div className="brigade-reportes">
                <button onClick={generateExcel}>Generar Excel</button>
                <button onClick={generateCSV}>Generar CSV</button>
            </div>
            <ul className="brigade-list">
                {brigades.map((brigade) => (
                    <li key={brigade._id} className="brigade-item">
                        <span className="brigade-name">{brigade.name}</span>
                        <button onClick={() => handleEdit(brigade)} className="brigade-button edit-button">Editar</button>
                        <button onClick={() => handleDelete(brigade._id)} className="brigade-button delete-button">Eliminar</button>
                        <button onClick={() => generatePDFForBrigade(brigade)} className="brigade-button pdf-button">
                            <i className="fas fa-file-pdf"></i> Generar Reporte
                        </button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BrigadeList;
