import React, { useEffect, useState } from 'react';
import axios from 'axios';
import RoleForm from './RoleForm';
import '../../css/roleList.css';

const RoleList = () => {
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const URL= process.env.REACT_APP_URL_LOCAL_HOST;
    
    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        const response = await axios.get(`${URL}/api/roles`);
        setRoles(response.data);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${URL}/api/roles/${id}`);
        fetchRoles();
    };

    const handleEdit = (role) => {
        setSelectedRole(role);
    };

    const handleSubmit = async (role) => {
        if (selectedRole) {
            await axios.put(`${URL}/api/roles/${selectedRole._id}`, role);
        } else {
            await axios.post(`${URL}/api/roles`, role);
        }
        setSelectedRole(null);
        fetchRoles();
    };

    return (
        <div className="role-list-container">
            <h2>Roles</h2>
            <RoleForm onSubmit={handleSubmit} role={selectedRole} />
            <ul className="role-list">
                {roles.map((role) => (
                    <li key={role._id}>
                        {role.name}
                        <div>
                            <button onClick={() => handleEdit(role)}>Editar</button>
                            <button onClick={() => handleDelete(role._id)} className="delete-btn">Eliminar</button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default RoleList;
