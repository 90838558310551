// src/components/Circunscripcion/CircunscripcionForm.js
import React, { useState, useEffect } from 'react';
import '../../css/circunscripcionForm.css';

const CircunscripcionForm = ({ onSubmit, circunscripcion, isOpen, onClose }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (circunscripcion) {
            setName(circunscripcion.name);
            setDescription(circunscripcion.description);
        } else {
            setName('');
            setDescription('');
        }
    }, [circunscripcion]);

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, description });
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Nombre de la Circunscripción"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <textarea
                        placeholder="Descripción"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <button type="submit">Guardar Circunscripción</button>
                </form>
            </div>
        </div>
    );
};

export default CircunscripcionForm;
