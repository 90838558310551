// src/components/Sector/SectorForm.js
import React, { useState, useEffect } from 'react';
import '../../css/sectorForm.css';

const SectorForm = ({ onSubmit, sector, brigades, circunscripciones, isOpen, onClose }) => {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [status, setStatus] = useState('Pendiente');
    const [assignedBrigade, setAssignedBrigade] = useState('');
    const [circunscripcion, setCircunscripcion] = useState('');
    const [scheduledDate, setScheduledDate] = useState('');
    const [coordinates, setCoordinates] = useState(sector?.coordinates || []);
    const [currentPage, setCurrentPage] = useState(0); // Para la paginación de coordenadas

    useEffect(() => {
        if (sector) {
            setName(sector.name);
            setUrl(sector.url);
            setStatus(sector.status);
            setAssignedBrigade(sector.assignedBrigade || '');
            setCircunscripcion(sector.circunscripcion || '');
            setScheduledDate(sector.scheduledDate ? new Date(sector.scheduledDate).toISOString().substring(0, 10) : '');
            setCoordinates(sector.coordinates || []);
        }
    }, [sector]);

    const handleAddCoordinate = () => {
        setCoordinates([...coordinates, [0, 0]]); // Añadir una coordenada inicializada en 0
        setCurrentPage(coordinates.length); // Ir a la última página después de añadir una coordenada
    };

    const handleCoordinateChange = (index, lat, lng) => {
        const newCoordinates = [...coordinates];
        newCoordinates[index] = [lat, lng];
        setCoordinates(newCoordinates);
    };

    const handlePrevious = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const handleNext = () => {
        if (currentPage < coordinates.length - 1) setCurrentPage(currentPage + 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ name, url, status, assignedBrigade, circunscripcion, scheduledDate, coordinates });
        setName('');
        setUrl('');
        setStatus('Pendiente');
        setAssignedBrigade('');
        setCircunscripcion('');
        setScheduledDate('');
        setCoordinates([]);
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close" onClick={onClose}>&times;</button>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="Nombre del Sector"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <input
                        type="text"
                        placeholder="URL"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        required
                    />
                    <select
                        value={circunscripcion}
                        onChange={(e) => setCircunscripcion(e.target.value)}
                        required
                    >
                        <option value="">Selecciona una Circunscripción</option>
                        {circunscripciones.map((circ) => (
                            <option key={circ._id} value={circ._id}>
                                {circ.name}
                            </option>
                        ))}
                    </select>
                    <input
                        type="date"
                        value={scheduledDate}
                        onChange={(e) => setScheduledDate(e.target.value)}
                        required
                    />
                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="Pendiente">Pendiente</option>
                        <option value="Proceso">Proceso</option>
                        <option value="Listo">Listo</option>
                    </select>
                    <select value={assignedBrigade} onChange={(e) => setAssignedBrigade(e.target.value)}>
                        <option value="">Selecciona una Brigada</option>
                        {brigades.map((brigade) => (
                            <option key={brigade._id} value={brigade._id}>{brigade.name}</option>
                        ))}
                    </select>

                    {/* Coordenadas con Paginación */}
                    <div className="coordinates-section">
                        <h4>Coordenada {currentPage + 1} de {coordinates.length}</h4>
                        {coordinates.length > 0 && (
                            <>
                                <input
                                    type="number"
                                    placeholder="Latitud"
                                    value={coordinates[currentPage][0]}
                                    onChange={(e) => handleCoordinateChange(currentPage, parseFloat(e.target.value), coordinates[currentPage][1])}
                                />
                                <input
                                    type="number"
                                    placeholder="Longitud"
                                    value={coordinates[currentPage][1]}
                                    onChange={(e) => handleCoordinateChange(currentPage, coordinates[currentPage][0], parseFloat(e.target.value))}
                                />
                                <div className="pagination-controls">
                                    <button type="button" onClick={handlePrevious} disabled={currentPage === 0}>
                                        Anterior
                                    </button>
                                    <button type="button" onClick={handleNext} disabled={currentPage === coordinates.length - 1}>
                                        Siguiente
                                    </button>
                                </div>
                            </>
                        )}
                        <button type="button" onClick={handleAddCoordinate}>Agregar Coordenada</button>
                    </div>

                    <button type="submit">Guardar Sector</button>
                </form>
            </div>
        </div>
    );
};

export default SectorForm;
