import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import axios from 'axios';
import Notification from '../Notification';
import Cookies from 'js-cookie';

Chart.register(...registerables);

const Dashboard = () => {
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;
    const [sectors, setSectors] = useState([]);
    const [circunscripcions, setCircunscripcions] = useState([]);
    const [brigadas, setBrigadas] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [filters, setFilters] = useState({
        circunscripcion: '',
        sector: '',
        brigada: '',
        estado: ''
    });
    const today = new Date().toISOString().split('T')[0];
    const token = Cookies.get('token');

    useEffect(() => {
        const filteredNotifications = sectors.filter(sector => {

            const sectorDate = sector.scheduledDate.split('T')[0];
            return sectorDate === today;
        }).map(sector => ({ message: `Notificación: Sector ${sector.name} programado para hoy. Status:${sector.status}` }));
        setNotifications(filteredNotifications);
    }, [sectors]);


    useEffect(() => {
        const fetchData = async () => {
            const [sectorRes, circunsRes, brigadaRes] = await Promise.all([
                axios.get(`${URL}/api/sectores`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${URL}/api/circunscripciones`, { headers: { Authorization: `Bearer ${token}` } }),
                axios.get(`${URL}/api/brigadas`, { headers: { Authorization: `Bearer ${token}` } })
            ]);
            setSectors(sectorRes.data);
            setFilteredData(sectorRes.data);
            setCircunscripcions(circunsRes.data);
            setBrigadas(brigadaRes.data);
        };


        fetchData();
    }, [token]);


    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        const newFilters = { ...filters, [name]: value };
        setFilters(newFilters);

        const filtered = sectors.filter(sector => {
            return (
                (newFilters.circunscripcion ? sector.circunscripcion === newFilters.circunscripcion : true) &&
                (newFilters.sector ? sector.name === newFilters.sector : true) &&
                (newFilters.brigada ? sector.assignedBrigade === newFilters.brigada : true) &&
                (newFilters.estado ? sector.status === newFilters.estado : true)
            );
        });

        setFilteredData(filtered.length ? filtered : sectors);
    };


    const prepareData = () => {
        const labels = [];
        const data = [];
        const backgroundColors = [];


        filteredData.forEach(sector => {
            labels.push(`${sector.name} (${sector.status})`);
            data.push(1);


            if (sector.status === 'Pendiente') {
                backgroundColors.push('#ef5ba1');
            } else if (sector.status === 'Proceso') {
                backgroundColors.push('#ffea00');
            } else if (sector.status === 'Listo') {
                backgroundColors.push('#00a651');
            }
        });
console.log(filteredData)
        return {
            labels,
            datasets: [
                {
                    label: 'Sectores y sus Estados',
                    data,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors.map(color => color.replace('0.6', '1')),
                    borderWidth: 1,
                },
            ],
        };
    };
    const chartData = prepareData();

    return (
        <div>
            <h2 className="titulo-sidebar">Panel de Sectores</h2>
            <div className="filters">
                <select name="circunscripcion" onChange={handleFilterChange}>
                    <option value="">Seleccionar Circunscripción</option>
                    {circunscripcions.map(circuns => (
                        <option key={circuns._id} value={circuns._id}>{circuns.name}</option>
                    ))}
                </select>
                <select name="sector" onChange={handleFilterChange}>
                    <option value="">Seleccionar Sector</option>
                    {sectors.map(sector => (
                        <option key={sector._id} value={sector.name}>{sector.name}</option>
                    ))}
                </select>
                <select name="brigada" onChange={handleFilterChange}>
                    <option value="">Seleccionar Brigada</option>
                    {brigadas.map(brigada => (
                        <option key={brigada._id} value={brigada._id}>{brigada.name}</option>
                    ))}
                </select>
                <select name="estado" onChange={handleFilterChange}>
                    <option value="">Seleccionar Estado</option>
                    <option value="Pendiente">Pendiente</option>
                    <option value="Proceso">Proceso</option>
                    <option value="Listo">Listo</option>
                </select>
            </div>
            <Bar
                data={chartData}
                options={{
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Detalle de Sectores por Estado',
                        },
                        tooltip: {
                            callbacks: {
                                label: (context) => {
                                    const sectorIndex = context.dataIndex;
                                    const sector = filteredData[sectorIndex];
                                    return `${sector.name}: ${sector.status}, Brigada: ${sector.assignedBrigade.name}`;
                                }
                            }
                        }
                    }
                }}
            />

            {notifications.map((notification, index) => (
                <Notification
                    key={index}
                    message={notification.message} 
                    onClose={() => setNotifications(notifications.filter((_, i) => i !== index))}
                />
            ))}
        </div>
    );

};

export default Dashboard;
