import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/HistoryDashboard.css';
import Cookies from 'js-cookie';

const HistoryDashboard = ({ sectorId }) => {
    const [history, setHistory] = useState([]);
    const token = Cookies.get('token');
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;

    useEffect(() => {
        const fetchHistory = async () => {
            try {
                const response = await axios.get(`${URL}/api/sectores/${sectorId}/history`, {
                    headers: {
                        Authorization: token ? `Bearer ${token}` : '',
                    }
                });

                // Ordenar el historial por fecha (más reciente primero)
                const sortedHistory = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
                setHistory(sortedHistory);
            } catch (error) {
                console.error('Error al obtener el historial', error);
            }
        };

        fetchHistory();
    }, [sectorId, token]);

    return (
        <div className="history-dashboard-container">
            <h2 className="history-dashboard-title">Historial de Cambios del Sector</h2>
            <table className="history-table">
                <thead>
                    <tr>
                        <th>Estado</th>
                        <th>Brigada Asignada</th>
                        <th>Comentarios</th>
                        <th>Fecha</th>
                    </tr>
                </thead>
                <tbody>
                    {history.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.status}</td>
                            <td>{entry.brigadeAssigned.name}</td>
                            <td>{entry.comment}</td>
                            <td>{new Date(entry.date).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default HistoryDashboard;
