import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import Dashboard from './components/dashboard/Dashboard';
import SupervisorDashboard from './components/supervisor/SupervisorDashboard';
import BrigadeList from './components/brigada/BrigadeList';
import RoleList from './components/Role/RoleList';
import UserList from './components/User/UserList';
import SectorList from './components/sectores/SectorList';
import CircunscripcionList from './components/Circunscripcion/CircunscripcionList';
import Mapa from './components/mapa/mapa';
import Imagen from './components/imagenes/imagen';
import Login from './Login';
import Cookies from 'js-cookie'; 

import './App.css';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    useEffect(() => {
        const token = Cookies.get('token'); 
        const role = Cookies.get('role'); 
        if (token) {
            setIsAuthenticated(true);
            setUserRole(role);
        }
    }, []);

    const handleLogin = (role) => {
        setIsAuthenticated(true);
        setUserRole(role);
    };

    const handleLogout = () => {
        Cookies.remove('token');
        Cookies.remove('role'); 
        setIsAuthenticated(false);
        setUserRole(null);
    };

    // Alterna el menú lateral
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <Router>
            <div className="app-container">
                {isAuthenticated && (
                    <aside className={`sidebar ${isSidebarOpen ? '' : 'collapsed'}`}>
                        <button onClick={toggleSidebar} className="menu-button">
                            &#9776; 
                        </button>
                        {isSidebarOpen && (
                            <>
                                <h3 className="titulo-sidebar">Gestión de Brigadas</h3>
                                <nav>
                                    <ul className="lista-sidebar">
                                        <li><Link to="/dashboard">Estadisticas</Link></li>
                                        {(userRole === 'Administrador' || userRole === 'Supervisor') && (
                                            <li><Link to="/supervisor">Supervisor</Link></li>
                                        )}
                                        {userRole === 'Administrador' && (
                                            <>
                                                <li><Link to="/brigadas">Brigadas</Link></li>
                                                <li><Link to="/usuarios">Usuarios</Link></li>
                                                <li><Link to="/roles">Roles</Link></li>
                                                <li><Link to="/sectores">Sectores</Link></li>
                                                <li><Link to="/circunscripcion">Circunscripcion</Link></li>
                                                <li><Link to="/mapa">Mapa</Link></li>
                                            </>
                                        )}
                                        <li><button onClick={handleLogout}>Cerrar Sesión</button></li>
                                    </ul>
                                </nav>
                            </>
                        )}
                    </aside>
                )}
                <main className={`main-content ${isSidebarOpen ? '' : 'collapsed'}`}>
                    <Routes>
                        <Route
                            path="/login"
                            element={
                                isAuthenticated ? (
                                    <Navigate to="/" />
                                ) : (
                                    <Login onLogin={handleLogin} />
                                )
                            }
                        />
                        {isAuthenticated ? (
                            <>
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/supervisor" element={userRole === 'Administrador' || userRole === 'Supervisor' ? <SupervisorDashboard /> : <Navigate to="/dashboard" />} />
                                <Route path="/brigadas" element={userRole === 'Administrador' ? <BrigadeList /> : <Navigate to="/dashboard" />} />
                                <Route path="/usuarios" element={userRole === 'Administrador' ? <UserList /> : <Navigate to="/dashboard" />} />
                                <Route path="/roles" element={userRole === 'Administrador' ? <RoleList /> : <Navigate to="/dashboard" />} />
                                <Route path="/sectores" element={userRole === 'Administrador' ? <SectorList /> : <Navigate to="/dashboard" />} />
                                <Route path="/circunscripcion" element={userRole === 'Administrador' ? <CircunscripcionList /> : <Navigate to="/dashboard" />} />
                                <Route path="/mapa" element={userRole === 'Administrador' ? <Mapa /> : <Navigate to="/dashboard" />} />
                                <Route path="/" element={userRole === 'Administrador' ? <Imagen/> : <Navigate to="/dashboard" />} />
                            </>
                        ) : (
                            <Route path="*" element={<Navigate to="/login" />} />
                        )}
                    </Routes>
                </main>
            </div>
        </Router>
    );
};

export default App;
