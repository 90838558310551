import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SectorForm from './SectorForm';
import '../../css/sectorList.css';
import Cookies from 'js-cookie';
import { saveAs } from 'file-saver';  // Para guardar archivos como Excel
import * as XLSX from 'xlsx';
import { jsPDF } from "jspdf";
const SectorList = () => {
    const [sectors, setSectors] = useState([]);
    const [filteredSectors, setFilteredSectors] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [brigades, setBrigades] = useState([]);
    const [circunscripciones, setCircunscripciones] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCircunscripcion, setSelectedCircunscripcion] = useState('');
    const [searchText, setSearchText] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const token = Cookies.get('token');
    const URL = process.env.REACT_APP_URL_LOCAL_HOST;

    useEffect(() => {
        fetchSectors();
        fetchBrigades();
        fetchCircunscripciones();
    }, []);

    useEffect(() => {
        filterSectors();
    }, [sectors, selectedCircunscripcion, searchText, startDate, endDate]);

    const fetchSectors = async () => {
        const response = await axios.get(`${URL}/api/sectores`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });

        const sortedSectors = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setSectors(sortedSectors);
    };

    const fetchBrigades = async () => {
        const response = await axios.get(`${URL}/api/brigadas`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        setBrigades(response.data);
    };

    const fetchCircunscripciones = async () => {
        const response = await axios.get(`${URL}/api/circunscripciones`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        setCircunscripciones(response.data);
    };

    const filterSectors = () => {
        const filtered = sectors.filter(sector => {
            const matchesCircunscripcion = selectedCircunscripcion ? sector.circunscripcion === selectedCircunscripcion : true;
            const matchesSearchText = sector.name.toLowerCase().includes(searchText.toLowerCase());

            // Filtrado por fecha si las fechas están definidas
            const sectorDate = new Date(sector.fechaCreacion);
            const isWithinDateRange =
                (!startDate || sectorDate >= new Date(startDate)) &&
                (!endDate || sectorDate <= new Date(endDate));

            return matchesCircunscripcion && matchesSearchText && isWithinDateRange;
        });
        setFilteredSectors(filtered);
    };

    const handleDelete = async (id) => {
        await axios.delete(`${URL}/api/sectores/${id}`, {
            headers: {
                Authorization: token ? `Bearer ${token}` : '',
            }
        });
        fetchSectors();
    };

    const handleEdit = (sector) => {
        setSelectedSector(sector);
        setModalOpen(true);
    };

    const handleAdd = () => {
        setSelectedSector(null);
        setModalOpen(true);
    };

    const handleSubmit = async (sector) => {
        if (selectedSector) {
            await axios.put(`${URL}/api/sectores/${selectedSector._id}`, sector, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        } else {
            await axios.post(`${URL}/api/sectores`, sector, {
                headers: {
                    Authorization: token ? `Bearer ${token}` : '',
                }
            });
        }
        setModalOpen(false);
        setSelectedSector(null);
        fetchSectors();
    };


   
    return (
        <div>
            <h2>Sectores</h2>
            <button className='btn-agregar-sector' onClick={handleAdd}>Agregar Sector</button>

            {/* Filtros */}
            <div className="filters">
                <select value={selectedCircunscripcion} onChange={(e) => setSelectedCircunscripcion(e.target.value)}>
                    <option value="">Seleccionar Circunscripción</option>
                    {circunscripciones.map((circunscripcion) => (
                        <option key={circunscripcion._id} value={circunscripcion._id}>
                            {circunscripcion.name}
                        </option>
                    ))}
                </select>

                <input
                    type="text"
                    placeholder="Buscar Sector"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />

                {/* Rango de fechas */}
                <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                />
                <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                />
            </div>

            {isModalOpen && (
                <SectorForm
                    isOpen={isModalOpen}
                    onClose={() => setModalOpen(false)}
                    onSubmit={handleSubmit}
                    sector={selectedSector}
                    brigades={brigades}
                    circunscripciones={circunscripciones}
                />
            )}
            
        
            <ul className='lista-sector'>
                {filteredSectors.map((sector) => (
                    <li key={sector._id}>
                        {sector.name}
                        <button className='btn-editar' onClick={() => handleEdit(sector)}>Editar</button>
                        <button onClick={() => handleDelete(sector._id)}>Eliminar</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SectorList;
